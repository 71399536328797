(function($){
    jQuery.fn.dropArea = function(options) {
        // variables
        var result;
        var list = [];
        var totalSize = 0;
        var totalProgress = 0;
        var bar;


        var dropArea = $(this);
        var poi = dropArea.attr('poi');
        var destinationUrl = dropArea.attr('att-upload');
        var refreshUrl = dropArea.attr('att-refresh');
        var deleteUrl = dropArea.attr('att-delete');

        // Initialisation
        function initHandler(){
            list = [];
            totalSize = 0;
            totalProgress = 0;
            dropArea.on('dragover', handleDragOver);
            dropArea.on('dragleave', handleDragLeave);
            dropArea.on('drop', handleDrop);
            dropArea.on('click', 'a.delete' , handleDelete);
            dropArea.on('click', '.no-picture' , handleDownload);
            dropArea.on('change', '.upload' , handleFileChange);
            result = dropArea.find('.result');
            $(dropArea).addClass('initialized');
        }
        if (!$(dropArea).hasClass('initialized')) initHandler();

        function handleDelete(event) {
            var no = $(this).attr('att-no');
            $(dropArea).addClass('uploading');
            $.ajax({
                type: 'POST',
                url: deleteUrl + '/'+ no
            })
                .done(function (data) {
                    if ((typeof data.result !== 'undefined') && (data.result == 1)) {
                        jQuery(dropArea).html(data.dropArea);
                    }
                    jQuery(dropArea).removeClass('uploading');
                }).fail(function (jqXHR, textStatus, errorThrown) {
                jQuery(dropArea).removeClass('uploading');
            });
            return false;
        }


        function handleDragOver(event) {
            event.stopPropagation();
            event.preventDefault();
            dropArea.addClass('hover');
        }


        function handleDownload(event) {
            dropArea.find('.upload').click();
            return false;
        }


        function handleFileChange(event) {
            processFiles(this.files);
            return false;
        }


        function handleDragLeave(event) {
            event.stopPropagation();
            event.preventDefault();
            dropArea.removeClass('hover');
        }


        function handleDrop(event) {
            event.stopPropagation();
            event.preventDefault();
            dropArea.removeClass('hover');
            processFiles(event.originalEvent.dataTransfer.files);
        }


        function processFiles(filelist) {
            $( dropArea ).next('.error').remove();

            if (!filelist || !filelist.length || list.length) return;
            totalSize = 0;
            totalProgress = 0;
            for (var i = 0; i < filelist.length && i < 6; i++) {
                list.push(filelist[i]);
                totalSize += filelist[i].size;
            }
            uploadNext();

            var barContainer = dropArea.find('.progressbar')[0];
            jQuery(barContainer).show();
            bar = new ProgressBar.Circle(barContainer, {
                color: '#aaa',
                strokeWidth: 8,
                trailWidth: 1,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    autoStyleContainer: false
                },
                from: { color: '#e3004a', width: 8 },
                to: { color: '#e3004a', width: 8 },
                // Set default step function for all animate calls
                step: function(state, circle) {
                    circle.path.setAttribute('stroke', state.color);
                    circle.path.setAttribute('stroke-width', state.width);

                    var value = Math.round(circle.value() * 100);
                    if (value === 0) {
                        circle.setText('0%');
                    } else {
                        circle.setText(value+'%');
                    }

                }
            });
            bar.text.style.fontSize = '14px';
        }


        function handleComplete(size) {
            totalProgress += size;
            if (bar !== undefined) bar.animate(1);
            uploadNext();
        }


        function handleProgress(event) {
            var progress = totalProgress + event.loaded;
            bar.animate(progress / totalSize);
        }


        function uploadFile(file, status) {

            var result = $(this).find('.result');
            // prepare XMLHttpRequest
            var xhr = new XMLHttpRequest();
            xhr.open('POST', destinationUrl);
            xhr.onload = function() {
                result.innerHTML = "";
                handleComplete(file.size);
            };
            xhr.onerror = function() {
                result.textContent = this.responseText;
                handleComplete(file.size);
            };
            xhr.upload.onprogress = function(event) {
                handleProgress(event);
            };
            xhr.upload.onloadstart = function(event) {
            };

            // prepare FormData
            var formData = new FormData();
            formData.append('file', file);
            xhr.send(formData);
        }


        function uploadNext() {
            if (list.length) {
                var nextFile = list.shift();
                if (nextFile.size >= 2090000) { // 2Mb
                    $( dropArea ).after( '<div class="error">Fichier trop volumineux. Max : 2Mo</div>' );
                    handleComplete(nextFile.size);
                } else {
                    uploadFile(nextFile, status);
                }
            } else {
                $.ajax({
                    type: 'GET',
                    url: refreshUrl
                })
                    .done(function (data) {
                        if ((typeof data.result !== 'undefined') && (data.result == 1)) {
                            dropArea.html(data.dropArea);
                        }
                        dropArea.removeClass('uploading');
                    }).fail(function (jqXHR, textStatus, errorThrown) {
                });
            }
        }
    };
})(jQuery);

function dropArea() {
    $(document).ready(function () {
        $('#dropArea').dropArea();
    });
}

export default dropArea;
