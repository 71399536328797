
$('.js-scrollTo').on('click', function () {
    var goscroll = false;
    var the_hash = $(this).attr("href");
    var regex = new RegExp("(.*)\#(.*)", "gi");
    var the_element = '';
    if (the_hash.match("\#(.+)")) {

        the_hash = the_hash.replace(regex, "$2");

        if ($("#" + the_hash).length > 0) {
            the_element = "#" + the_hash;
            goscroll = true;
        }
        else if ($("a[name=" + the_hash + "]").length > 0) {
            the_element = "a[name=" + the_hash + "]";
            goscroll = true;
        }

        if (goscroll) {
            var headerHeight = $('header').outerHeight();
            var to = $(the_element).offset().top - headerHeight - 20;
            $('html, body').animate({
                scrollTop: to
            }, 750);
            return false;
        }
    }
});

function scrollFunction() {
    var posScroll = $(document).scrollTop();
    if(posScroll >= 50)
    {
        $('.scroll-to-top').fadeIn(600);
    }
    else
    {
        $('.scroll-to-top').fadeOut(600);
    }

    if(posScroll >= 70)
    {
        $('body').addClass("top-menu");
    }
    else
    {
        $('body').removeClass("top-menu");
    }

    var windowHeight = $( window ).height();
    var headerHeight = $('header').outerHeight();
    var bannerHeight = $( '.banner-pp, .slide-pp' ).outerHeight();
    var sidebarHeight = $( '.sidebar' ).outerHeight();
    if (bannerHeight === null) bannerHeight = 0;

    var pos = headerHeight + bannerHeight + 10;

    if ((posScroll - pos  + ((windowHeight - sidebarHeight) / 2)) > 0 ) pos = posScroll + ((windowHeight - sidebarHeight )/ 2);
    $( '.sidebar' ).css('top', pos +'px');
    $( '.sidebar' ).css('opacity', 1);

}


function scrollInit() {
    var goscroll = false;
    var the_hash = $(location).attr('href');
    var regex = new RegExp("(.*)\#(.*)", "gi");
    var the_element = '';
    if (the_hash.match("\#(.+)")) {

        the_hash = the_hash.replace(regex, "$2");

        if ($("#" + the_hash).length > 0) {
            the_element = "#" + the_hash;
            goscroll = true;
        }
        else if ($("a[name=" + the_hash + "]").length > 0) {
            the_element = "a[name=" + the_hash + "]";
            goscroll = true;
        }

        if (goscroll) {
            var headerHeight = $('header').outerHeight();
            var to = $(the_element).offset().top - headerHeight - 20;
            $('html, body').animate({
                scrollTop: to
            }, 750);
            return false;
        }
    }
};
window.onscroll = function() {scrollFunction()};
scrollInit();

export default scroll;
