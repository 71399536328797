import 'picturefill';
import cookieConsent from './cookieConsent';
import videolink from './videolink';
import selectAjax from "./selectAjax";
import loadMap from "./loadMap";
import openModal from "./openModal";
import dropArea from "./drop.area";
import scroll from "./scroll";

document.addEventListener('DOMContentLoaded', () => {
    cookieConsent();
    videolink();
    selectAjax();
    openModal();
    dropArea();
    loadMap();
    scroll();

});

$(document).ready(function () {

    //********** Menu ******************************************//
    var inMenu = false;
    var inSubMenu = false;

    $('.btn-navbar').click(function (e) {
        e.preventDefault();
        $('.header-navbar').toggleClass('out');
    });

    //********** Validation formulaire ajax ********************//
    $(document).on('submit', 'form[ajax]', function (e) {
        const container = $(e.target).parent();
        const formData = new FormData(this);

        e.preventDefault();
        if ($(this).find('[type="submit"]').hasClass('disabled')) return;
        $(container).addClass('loading');
        $(container).find('.form-error').html('').removeClass("in");
        $(container).find('.form-success').html('').removeClass("in");
        $.ajax({
            type: 'POST',
            url: $(this).attr('action'),
            data: formData,
            processData: false,
            contentType: false,
        })
            .done(function (data) {
                $(container).removeClass('loading');
                if (typeof data.form !== 'undefined') {
                    container.html(data.form);
                }
                if ((typeof data.url !== 'undefined') && (data.url !== null) && (data.url !== '')) {
                    var delay = 0;
                    if ((typeof data.urlDelay !== 'undefined') && (data.urlDelay !== null) && (data.urlDelay !== '')) {
                        delay = data.urlDelay;
                    }
                    setTimeout(function () {
                        window.location.replace(data.url);
                    }, delay);

                } else if ((typeof data.change !== 'undefined') && (data.change === 1)) {
                    window.location.reload();
                }
                if ((typeof data.disabled !== 'undefined') && (data.disabled === 1)) {
                    $(container).find('[type="submit"]').addClass('disabled');
                }
                if ((typeof data.msgko !== 'undefined')) {
                    $(container).find('.form-error').html(data.msgko);
                    if (data.msgko === '')
                        $(container).find('.form-error').removeClass('in');
                    else
                        $(container).find('.form-error').addClass('in');
                }
                if ((typeof data.msgok !== 'undefined')) {
                    $(container).find('.form-success').html(data.msgok);
                    if (data.msgok === '')
                        $(container).find('.form-success').removeClass('in');
                    else
                        $(container).find('.form-success').addClass('in');
                }
                if ((typeof data.replaceId !== 'undefined') && (data.replaceId !== '')) {
                    if ((typeof data.replaceContent !== 'undefined') && (data.replaceContent !== '')) {
                        $(data.replaceId).replaceWith(data.replaceContent);
                    }
                }
                if ((typeof data.autoclose !== 'undefined')) {
                    setTimeout(function () {
                        container.closest(".modal").modal('hide');
                    }, data.autoclose);
                }
                if ((typeof data.scrollTo !== 'undefined') && (data.scrollTo !== '')) {
                    $('html, body').animate({
                        scrollTop: $(data.scrollTo).offset().top
                    }, 750);
                }
                if ((typeof data.callback !== 'undefined') && (data.callback !== '')) {

                    var function_name = "" + data.callback;
                    if (function_name == 'afterAdPopup') afterAdPopup();
                }
            })
            .fail(function (/* jqXHR, textStatus, errorThrown */) {
                $(container).removeClass('loading');
            });
    });
    $('.form-s2').select2();

});
