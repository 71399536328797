function openPoiModal(id, key) {
    $("#poiModal").modal('show');
    var wrapper = $("#poiModal .modal-dialog");
    $("#poiModal").addClass('loading');
    wrapper.html('');
    $.ajax({
        type: 'GET',
        url:  Routing.generate('public_modal_poi', {'poi' : id, 'key': key}),
        contentType: "application/json",
        dataType: 'json',
        success: function(data) {
            if ((typeof data.result !== 'undefined') && (data.result == 1)) {
                wrapper.html(data.content);
            }
            $("#poiModal").removeClass('loading');
        },
        error: function(e) {
            $("#poiModal").removeClass('loading');
        }
    });
}

function openModalPurchase(id) {
    $("#purchasePoiModal").modal('show');
    var wrapper = $("#purchasePoiModal .modal-dialog");
    $("#purchasePoiModal").addClass('loading');
    wrapper.html('');
    var route = $(wrapper).attr('data-route');

    if (route != "") {
        $.ajax({
            type: 'POST',
            url: route+"?id="+id,
            contentType: "application/json",
            dataType: 'json',
            success: function(data) {
                if ((typeof data.result !== 'undefined') && (data.result == 1)) {
                    wrapper.html(data.content);
                }
                $("#purchasePoiModal").removeClass('loading');
            },
            error: function(e) {
                $("#purchasePoiModal").removeClass('loading');
            }
        });
    }
}

function modals() {
    $(document).on('click', '.btn--modal-poi', function(e) {
        e.preventDefault();
        var id = $(this).attr('att-poi');
        var key = $(this).attr('att-key');
        openPoiModal(id, key);
        return false;
    });

    // $(document).on('click', '.btn--modal-purchase', function(e) {
    //     e.preventDefault();
    //     var id = $(this).attr('att-poi');
    //     openModalPurchase(id);
    //     return false;
    // });

    $(document).on('show.bs.modal', '.modal', function () {
        var zIndex = 1040 + (10 * $('.modal:visible').length);
        $(this).css('z-index', zIndex);
        setTimeout(function() {
            $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex - 1).addClass('modal-stack');
        }, 0);
    });

    $(document).on('hidden.bs.modal', '.modal', function () {
        $('.modal:visible').length && $(document.body).addClass('modal-open');
    });
}

export default modals;
