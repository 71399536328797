//********** Search *****************************************//
function selectChange() {
    var terms = [];
    $('select#poi_form_category :selected').each(function(){
        terms.push($(this).val());
    });
    terms = terms.join();

    $.ajax({
        type: 'GET',
        url: '/public/api/subcategories',
        contentType: "application/json",
        dataType: 'json',
        data: $.param({'terms' : terms}),
        success: function(data) {
            if (typeof data.results !== 'undefined') {

                var items = [];
                var terms = [];
                $('select#search_district :selected').each(function(){
                    terms.push($(this).val());
                });
                data.results.forEach(function(d){
                    if (terms.indexOf(d.id) != -1 ) {
                        items.push({'id': d.id, 'text': d.name, 'selected' : true });
                    } else {
                        items.push({'id': d.id, 'text': d.name});
                    }

                });
                var $select = $('select#poi_form_subcategory');

                $select.select2().empty();
                $select.select2({
                    data: items,
                    cache: false,
                    language: "fr"
                });
            }
        },
        error: function(e) {
        }
    });

}




function init() {
    console.log("selectAjax init");
    $( "select#poi_form_category" ).change(function() {
        selectChange();
    });
    selectChange();
}

function selectAjax() {
    $(document).ready(function () {
        // console.log("selectAjax");
        // init();
    });
}

export default selectAjax;
