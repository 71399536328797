
var pois = [];
var infoopened;

/**
 * Init de la carte
 * @param center
 */
function initMap(center, zoom) {
    var homemap = document.getElementById('homemap');
    if (homemap != null ) {
        var map;
        map = new google.maps.Map(document.getElementById('homemap'), {
            center: center,
            zoom: zoom,
            gestureHandling: 'cooperative',
            mapTypeControl: false,
            streetViewControl: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.LEFT_TOP
            },
        });

        map.addListener('idle', function() {
            refreshMapDatas(map);
        });

        map.addListener('dragend', function() {
            refreshMapDatas(map);
        });

        map.addListener('zoom_changed', function() {
            refreshMapDatas(map);
        });
    }
}

/**
 *
 */
function refreshMapDatas(map) {
    var bounds = map.getBounds().toJSON();
    var data = {
        swLat: bounds.south,
        swLng: bounds.west,
        neLat: bounds.north,
        neLng: bounds.east,
    };
    $.ajax({
        type: 'GET',
        url:  Routing.generate('public_map_datas', {}),
        contentType: "application/json",
        dataType: 'json',
        processData: true,
        data: data,
        success: function(data) {
            console.log(data);
            /**
             * Suppression des pois non visible
             */
            for (let i = (pois.length - 1); i >= 0 ; i--) {
                const index = data.findIndex(d => d.id === pois[i].id);
                if (index < 0 ) {
                    pois[i].marker.setMap(null);
                    pois[i].infoWindow = null;
                    pois.splice(i, 1);
                }
            }

            /**
             * Ajout des Pois
             */
            var full = '';
            // Ajout des Pois présents
            for (let i = 0; i < data.length; i++) {
                const index = pois.findIndex(d => d.id === data[i].id);
                if (index < 0 ) {
                    full = (data[i].certified === true ) ? '_full' : '';
                    data[i].marker = new google.maps.Marker({
                        position: {lat: data[i].geoLat, lng: data[i].geoLng},
                        icon: {
                            url: '/frontend/img/general/marker_' + data[i].categoryInternalName + full + '.png',
                            origin: new google.maps.Point(0, 0),
                            anchor: new google.maps.Point(14, 35),
                            scaledSize: new google.maps.Size(28, 40),
                        },
                        zIndex: 98,
                        map: map
                    });

                    var contentString = '<div id="content" style="text-align: center">'+
                        '<h3>' + data[i].name + '</h3>'+
                        '<input class="btn btn--primary btn--modal-poi" type="button" value="Voir +" att-poi="' + data[i].id + '" att-key="' + data[i].key +'"/>'
                    '</div>';

                    data[i].infoWindow = new google.maps.InfoWindow({
                        content: data[i].html
                    });

                    data[i].marker.addListener('click', function() {
                        if (typeof( infoopened ) != 'undefined') infoopened.close();
                        data[i].infoWindow.open(map, data[i].marker);
                        infoopened = data[i].infoWindow;
                    });
                    pois.push(data[i]);
                }
            }
        },
        error: function(e) {
        }
    });


}


/**
 *
 */
function loadMap() {
    /*******************************************
     * Initialisations
     */
    $(document).ready(function() {
        if(navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function(position){
                center = {lat: position.coords.latitude, lng: position.coords.longitude};
                var zoom = 10;
                initMap(center, zoom);
            }, function() {
                var center = {lat:45.833619, lng: 1.261105};
                var zoom = 7;
                initMap(center, zoom);
            });
        } else {
            var center = {lat:45.833619, lng: 1.261105};
            var zoom = 7;
            initMap(center);
        }
    });
}

export default loadMap;
